/* @import '~antd/dist/antd.css'; */

/* @font-face {
    font-family: 'Akkurat';
    src: url(../fonts/akkurat/Akkurat-Normal.woff);
    font-weight: 600;
}

@font-face {
    font-family: 'Akkurat';
    src: url(../fonts/akkurat/Akkurat-Bold.woff);
    font-weight: 800;
} */

@font-face {
  font-family: 'Akkurat';
  src: url(../fonts/akkurat/Akkurat-Normal.woff);
  font-weight: 400;
}

@font-face {
  font-family: 'Albertus';
  src: url(../fonts/albertus/albertus-medium-regular.woff);
  font-weight: 600;
}

@font-face {
  font-family: 'Fortesque';
  src: url(../fonts/fortesque/Fortescue-Regular.woff);
  font-weight: 600;
}

@font-face {
  font-family: 'Eksell';
  src: url(../fonts/eksell/ekselldisplay-medium.woff);
  font-weight: 600;
}

@font-face {
  font-family: 'ITC-Clearface';
  font-style: 'regular';
  font-weight: 400;
  src: url(../fonts/itc-clearface/ClearfaceStd-Regular.otf);
}

@font-face {
  font-family: 'ITC-Clearface';
  font-style: 'italic';
  font-weight: 400;
  src: url(../fonts/itc-clearface/ClearfaceStd-Italic.otf);
}

@font-face {
  font-family: 'ITC-Clearface';
  src: url(../fonts/itc-clearface/ClearfaceStd-Bold.otf);
  font-weight: 500;
}

@font-face {
  font-family: 'ITC-Clearface';
  src: url(../fonts/itc-clearface/ClearfaceStd-BoldItalic.otf);
  font-weight: 500;
  font-style: 'italic';
}

@font-face {
  font-family: 'UniversalSans';
  src: url(../fonts/universal/UniversalSans/UniversalSans-v1-1-0-40-69-223122111121-300-TRIAL.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'UniversalSans';
  src: url(../fonts/universal/UniversalSans/UniversalSans-v1-1-0-40-69-223122111121-400-TRIAL.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'UniversalSans';
  src: url(../fonts/universal/UniversalSans/UniversalSans-v1-1-0-40-69-223122111121-500-TRIAL.ttf);
  font-weight: 500;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(../fonts/universal/UniversalSans/UniversalSans-v1-1-0-40-69-223122111121-600-TRIAL.ttf);
  font-weight: 600;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(../fonts/universal/UniversalSans/UniversalSans-v1-1-0-40-69-223122111121-700-TRIAL.ttf);
  font-weight: 700;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(../fonts/universal/UniversalSans/UniversalSans-v1-1-0-40-69-223122111121-800-TRIAL.ttf);
  font-weight: 800;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(../fonts/universal/UniversalSans/UniversalSans-v1-1-0-40-69-223122111121-900-TRIAL.ttf);
  font-weight: 900;
}

@font-face {
  font-family: 'Canela';
  src: url(../fonts/canela/Canela-Regular.woff);
  font-weight: 500;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: '16px';
  background: '#000';
}
