.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
	cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #878a8c;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
	padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "\25B6";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}

/* @import '~antd/dist/antd.css'; */

/* @font-face {
    font-family: 'Akkurat';
    src: url(../fonts/akkurat/Akkurat-Normal.woff);
    font-weight: 600;
}

@font-face {
    font-family: 'Akkurat';
    src: url(../fonts/akkurat/Akkurat-Bold.woff);
    font-weight: 800;
} */

@font-face {
  font-family: 'Akkurat';
  src: url(/static/media/Akkurat-Normal.1f470768.woff);
  font-weight: 400;
}

@font-face {
  font-family: 'Albertus';
  src: url(/static/media/albertus-medium-regular.4b480bcf.woff);
  font-weight: 600;
}

@font-face {
  font-family: 'Fortesque';
  src: url(/static/media/Fortescue-Regular.17ae1221.woff);
  font-weight: 600;
}

@font-face {
  font-family: 'Eksell';
  src: url(/static/media/ekselldisplay-medium.3da621e7.woff);
  font-weight: 600;
}

@font-face {
  font-family: 'ITC-Clearface';
  font-style: 'regular';
  font-weight: 400;
  src: url(/static/media/ClearfaceStd-Regular.b0cc4fe7.otf);
}

@font-face {
  font-family: 'ITC-Clearface';
  font-style: 'italic';
  font-weight: 400;
  src: url(/static/media/ClearfaceStd-Italic.e1f1fb47.otf);
}

@font-face {
  font-family: 'ITC-Clearface';
  src: url(/static/media/ClearfaceStd-Bold.725e7434.otf);
  font-weight: 500;
}

@font-face {
  font-family: 'ITC-Clearface';
  src: url(/static/media/ClearfaceStd-BoldItalic.fb4b1311.otf);
  font-weight: 500;
  font-style: 'italic';
}

@font-face {
  font-family: 'UniversalSans';
  src: url(/static/media/UniversalSans-v1-1-0-40-69-223122111121-300-TRIAL.44563e2b.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'UniversalSans';
  src: url(/static/media/UniversalSans-v1-1-0-40-69-223122111121-400-TRIAL.cd736fdd.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'UniversalSans';
  src: url(/static/media/UniversalSans-v1-1-0-40-69-223122111121-500-TRIAL.e6aa6e43.ttf);
  font-weight: 500;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(/static/media/UniversalSans-v1-1-0-40-69-223122111121-600-TRIAL.dba36989.ttf);
  font-weight: 600;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(/static/media/UniversalSans-v1-1-0-40-69-223122111121-700-TRIAL.42f759a5.ttf);
  font-weight: 700;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(/static/media/UniversalSans-v1-1-0-40-69-223122111121-800-TRIAL.d91f40f3.ttf);
  font-weight: 800;
}
@font-face {
  font-family: 'UniversalSans';
  src: url(/static/media/UniversalSans-v1-1-0-40-69-223122111121-900-TRIAL.0b143ebd.ttf);
  font-weight: 900;
}

@font-face {
  font-family: 'Canela';
  src: url(/static/media/Canela-Regular.ac6a5242.woff);
  font-weight: 500;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: '16px';
  background: '#000';
}

